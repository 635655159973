import React, { useState, useEffect } from "react";
import { FiUsers } from "react-icons/fi";
import { BiHistory, BiUserCheck, BiChart, BiUserVoice, BiMinus, BiNews } from "react-icons/bi";
import { MdOutlineReportGmailerrorred } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { setActiveMenu, setCurrentEnvironment } from "../store/reducer";
import { GrDocumentConfig, GrLineChart } from "react-icons/gr";
import { MdPostAdd } from "react-icons/md";
import { FaFileInvoice, FaUserClock } from "react-icons/fa";
import { LuImage } from "react-icons/lu";
import { BsFileCheckFill, BsPlusLg } from "react-icons/bs";
import { FaPhoneAlt } from "react-icons/fa";
import { GoOrganization } from "react-icons/go";
import { PiUsersFourDuotone, PiNewspaperClippingDuotone } from "react-icons/pi";
import { HiOutlineNewspaper } from "react-icons/hi";
import { FaShoppingBag } from "react-icons/fa";
import { RiRefund2Line } from "react-icons/ri";

import { LuListTree } from "react-icons/lu";
import { TbPrompt } from "react-icons/tb";
import { IoSettingsSharp } from "react-icons/io5";
import { AxiosApi } from "../utility/axios";

const SidebarMenu = ({ refundReqNo }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);
  const activeMenu = useSelector(({ activeMenu }) => activeMenu);
  const loginDetails = useSelector(({ loginDetails }) => loginDetails);
  const currEnv = useSelector(({ currEnv }) => currEnv);

  const [radioValue, setRadioValue] = useState(currEnv);
  const [menuList, setMenuList] = useState([]);

  const AllMenuList = [
    {
      id: 16,
      title: "Refund Requests",
      icon: <RiRefund2Line />,
      path: "/refund-credit-requests",
      access: ["admin"],
    },
    {
      id: 1,
      title: "1-Click Blog Writer",
      icon: <BiHistory />,
      path: "/",
      access: ["admin", "sales", "reviewer"],
    },
    {
      id: 2,
      title: "Blog Co-Pilot",
      icon: <GrLineChart />,
      path: "/article-optimized-blog",
      access: ["admin", "sales", "reviewer"],
    },
    {
      id: 3,
      title: "Social Media Post",
      icon: <MdPostAdd />,
      path: "/social-media-post",
      access: ["admin", "sales", "reviewer"],
    },
    /* {
      id: 20,
      title: "Brand Product Images",
      icon: <LuImage />,
      path: "/brand-product-images",
      access: ["admin", "sales"],
    }, */
    {
      id: 21,
      title: "Media Library Images",
      icon: <LuImage />,
      path: "/media-library-images",
      access: ["admin", "sales"],
    },
    {
      id: 22,
      title: "Newsletters",
      icon: <HiOutlineNewspaper />,
      path: "/news-letters",
      access: ["admin", "sales", "reviewer"],
    },
    {
      id: 29,
      title: "CMI Newsletter",
      icon: <BiNews />,
      path: "/cmi-newsletter",
      access: ["admin", "sales"],
    },
    {
      id: 25,
      title: "E-Commerce",
      icon: <FaShoppingBag />,
      path: "/e-commerce",
      access: ["admin", "sales"],
    },
    {
      id: 28,
      title: "Azgo-newsletter",
      icon: <HiOutlineNewspaper />,
      path: "/azgo-newsletter",
      access: ["admin", "sales"],
    },
    // {
    //   id: 26,
    //   title: "E-Commerce",
    //   icon: <FaShoppingBag />,
    //   path: "/e-commerce",
    //   access: ["admin", "sales"],
    // },
    {
      id: 23,
      title: "Press Release",
      icon: <PiNewspaperClippingDuotone />,
      path: "/press-release",
      access: ["admin", "sales", "reviewer"],
    },
    {
      id: 24,
      title: "Topic Suggestions",
      icon: <LuListTree />,
      path: "/topic-suggestions",
      access: ["admin", "sales"],
    },
    {
      id: 30,
      title: "InvestIdea",
      icon: <PiNewspaperClippingDuotone />,
      path: "/invest-idea-history",
      access: ["admin", "sales"]
    },
    // {
    //   id: 32,
    //   title: "New Credit History Page",
    //   icon: <PiNewspaperClippingDuotone />,
    //   path: "/credit-usage-new",
    //   access: ["admin", "sales"]
    // },
    {
      id: 31,
      title: "Happy Dot Blog Writer",
      icon: <PiNewspaperClippingDuotone />,
      path: "/happydot-blog", 
      access: ["admin", "sales"]
    },
    {
      id: 4,
      title: "Analytics",
      icon: <BiChart />,
      path: "/analytics",
      access: ["admin", "sales"],
    },
    {
      id: 5,
      title: "Users",
      icon: <FiUsers />,
      path: "/users",
      access: ["admin", "sales"],
    },
    {
      id: 6,
      title: "Admins",
      icon: <PiUsersFourDuotone />,
      path: "/admins",
      access: ["admin"],
    },
    {
      id: 20,
      title: "Organizations",
      icon: <GoOrganization />,
      path: "/organizations",
      access: ["admin"],
    },
    {
      id: 100,
      title: "Other Settings",
      icon: <IoSettingsSharp />,
      path: "/other-settings",
      access: ["admin"],
      children: [
        {
          id: 25,
          title: "Tone Of Voice",
          icon: <BiUserVoice />,
          path: "/tone-of-voice",
          access: ["admin"],
        },
        {
          id: 26,
          title: "System Prompts",
          icon: <TbPrompt />,
          path: "/system-prompts",
          access: ["admin"],
        },
        {
          id: 27,
          title: "Deduct Credits Config",
          icon: <GrDocumentConfig />,
          path: "/deduct-credits",
          access: ["admin"],
        },
        /* {
          id: 7,
          title: "Blog Creation",
          icon: <MdEditNote />,
          path: "/blog-creation",
          access: ["admin"],
        },
        {
          id: 8,
          title: "Credit Requests",
          icon: <BiUserCheck />,
          path: "/credit-requests",
          access: ["admin"],
        },
        {
          id: 9,
          title: "Product",
          icon: <MdProductionQuantityLimits />,
          path: "/product",
          access: ["admin"],
        }, */
        {
          id: 10,
          title: "All Plans",
          icon: <BsFileCheckFill />,
          path: "/all-plans",
          access: ["admin"],
        },
        {
          id: 11,
          title: "All Invoices",
          icon: <FaFileInvoice />,
          path: "/all-invoices",
          access: ["admin"],
        },
        /* {
          id: 12,
          title: "Subscriptions",
          icon: <IoMdGift />,
          path: "/subscriptions",
          access: ["admin"],
        },
        {
          id: 13,
          title: "Discount Coupan",
          icon: <TbDiscount2 />,
          path: "/discount-coupan",
          access: ["admin"],
        },
        {
          id: 14,
          title: "Create Plan",
          icon: <IoIosCreate />,
          path: "/create-plan",
          access: ["admin"],
        },
        {
          id: 15,
          title: "Refund Requests",
          icon: <RiRefund2Line />,
          path: "/refund-requests",
          access: ["admin"],
        }, */
        {
          id: 17,
          title: "Error Logs",
          icon: <MdOutlineReportGmailerrorred />,
          path: "/error-logs/errors",
          access: ["admin"],
        },
      ]
    },
    {
      id: 18,
      title: "Enterprise Plan Req", // "Contact Us",
      icon: <FaPhoneAlt />,
      path: "/contact-us",
      access: ["admin", "sales"],
    },
    {
      id: 19,
      title: "Trying To Signup",
      icon: <FaUserClock />,
      path: "/trying-to-signup",
      access: ["admin", "sales"],
    },
  ];

  useEffect(() => {
    if (loginDetails && loginDetails.role) {
      const activeMenuList = [];
      AllMenuList.filter((menu) => {
        if (menu.access.includes(loginDetails.role)) {
          activeMenuList.push(menu);
        }
        return menu;
      });

      if (loginDetails.role === "admin") {
        const findMenu = activeMenuList.findIndex((menu) => menu.title === "Refund Requests");
        if (findMenu !== -1) {
          activeMenuList[findMenu].isNoLabel = refundReqNo;
        }
        setMenuList(activeMenuList);
      } else {
        setMenuList(activeMenuList);
      }
    }
  }, [])

  useEffect(() => {
    const currentUrlName = window.location.href.split(window.location.origin)[1];
    const currentMenu = AllMenuList.find((ele) => {
      if (currentUrlName === "/") {
        return false;
      } else if (ele.path.indexOf(`/${currentUrlName.split("/")?.[1]}`) !== -1) {
        return ele;
      } else if (ele.children && ele.children.length > 0) {
        return ele.children.find((ele2) => ele2.path.indexOf(`/${currentUrlName.split("/")?.[1]}`) !== -1);
      } else {
        return false;
      }
    });
    if (currentMenu) {
      dispatch(setActiveMenu(currentMenu.id));
    } else {
      dispatch(setActiveMenu(1));
    }
  }, []);

  const handleOnChangeCurrentEnvironment = async (e) => {
    const val = e.currentTarget.value;
    setRadioValue(val);
    await dispatch(setCurrentEnvironment(val));
    setTimeout(() => {
      window.location.reload();
    }, 400);
  }

  return (
    <div className="homeContent bg-white">
      <div className="h-100 flex-coul w-100">
        <ul className="list-group">
          <ButtonGroup className="mb-3">
            <ToggleButton
              id={`staging`} type="radio" variant="outline-primary" name="envButton" style={{ width: "100px" }}
              value={"staging"} checked={radioValue === "staging"} onChange={handleOnChangeCurrentEnvironment}
            >
              Staging
            </ToggleButton>
            <ToggleButton
              id={`prod`} type="radio" variant="outline-primary" name="envButton" style={{ width: "100px" }}
              value={"prod"} checked={radioValue === "prod"} onChange={handleOnChangeCurrentEnvironment}
            >
              Prod
            </ToggleButton>
          </ButtonGroup>
          {menuList && menuList.length > 0 && menuList.map((item) => {
            if (item && item.children && item.children.length > 0) {
              return (
                <HandleMenuWithSubmenu item={item} activeMenu={activeMenu} key={item.id} />
              );
            } else {
              return (
                <li
                  key={item.id} onClick={() => { dispatch(setActiveMenu(item.id)); navigate(item.path); }} onKeyDown={() => { }}
                  className={`d-flex align-items-center list-group-item ${activeMenu === item.id && "active"}`}
                >
                  {item.icon}
                  <h6 className="fw-semibold mb-0">{item?.title}</h6>
                  {item.isNoLabel && item.isNoLabel > 0 ? (
                    <span className="badge rounded-pill bg-primary ms-auto" style={{ padding: "6px" }}>{item.isNoLabel}</span>
                  ) : null}
                </li>
              );
            }
          })}
        </ul>
        <div>
          <p className="mt-5 m-3">© addlly.ai {new Date().getFullYear()}</p>
        </div>
      </div>
    </div>
  );
};

const HandleMenuWithSubmenu = ({ item, activeMenu }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(activeMenu === item.id ? true : false);

  return (
    <div className={`main-menu-wrapper ${isSubMenuOpen ? "sub-menu-open" : ""}`}>
      <li
        key={item.id} onClick={() => { dispatch(setActiveMenu(item.id)); setIsSubMenuOpen(!isSubMenuOpen); }}
        className={`d-flex align-items-center ${activeMenu === item.id && "active"}`} onKeyDown={() => { }}
      >
        {item.icon}
        <h6 className="fw-semibold mb-0">{item?.title}</h6>
        <p className="text-dark ms-auto">{isSubMenuOpen ? <BiMinus className="fs-5" /> : <BsPlusLg className="fs-5" />}</p>
      </li>

      {item.children && item.children.length > 0 && isSubMenuOpen && (
        <ul className="sub-menulist">
          {item.children.map((subItem) => (
            <li
              key={subItem.id} onClick={() => { dispatch(setActiveMenu(subItem.id)); navigate(subItem.path); }}
              className={`d-flex align-items-center ${activeMenu === subItem.id && "active"}`} onKeyDown={() => { }}
            >
              {subItem.icon}
              <h6 className="fw-semibold mb-0">{subItem?.title}</h6>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default SidebarMenu;
