import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { ToastBar, Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "react-tooltip/dist/react-tooltip.css";

import Loader from "./Components/Loader";
import HeaderSidebar from "./Components/HeaderSidebar";
import { resetReduxData, setLoginDetails } from "./store/reducer";
import { AxiosApi } from "./utility/axios";

import Users from "./Pages/Users";
import Admins from "./Pages/Admins";
import Login from "./Pages/Login";
import Organizations from "./Pages/Organizations";
import ArticleHistory from "./Pages/ArticleHistory";
import ArticleHistoryDetails from "./Pages/ArticleHistoryDetails";
// import BlogCreation from "./Pages/BlogCreation";
import ArticleOptimizedBlogHistory from "./Pages/ArticleOptimizedBlogHistory";
import SocialMediaPostHistory from "./Pages/SocialMediaPostHistory";
import ArticleOptimizedBlogDetails from "./Pages/ArticleOptimizedBlogDetails";
import SocialMediaPostDetails from "./Pages/SocialMediaPostDetails";
// import BrandProductImagesHistory from "./Pages/BrandProductImagesHistory";
import MediaLibraryImagesHistory from "./Pages/MediaLibraryImagesHistory";
import NewslettersHistory from "./Pages/NewslettersHistory";
import NewslettersDetails from "./Pages/NewslettersDetails";
import PressReleaseHistory from "./Pages/PressReleaseHistory";
import PressReleaseDetails from "./Pages/PressReleaseDetails";
import TopicSuggestionsHistory from "./Pages/TopicSuggestionsHistory";
import TopicSuggestionsDetails from "./Pages/TopicSuggestionsDetails";
import ToneOfVoiceHistory from "./Pages/ToneOfVoiceHistory";
import SystemPromptsHistory from "./Pages/SystemPromptsHistory";
import DeductCreditsDetails from "./Pages/DeductCreditsDetails";
import RefundCreditRequests from "./Pages/RefundCreditRequests";
import UserTransactionHistory from "./Pages/UserTransactionHistory";
import UserLog from "./Pages/UserLog";
// import RefundRequests from "./Pages/RefundRequests";
import Invoices from "./Pages/Invoices";
import CreatePlans from "./Components/CreatePlans";
// import Products from "./Pages/Products";
// import DiscountCoupan from "./Pages/DiscountCoupan";
import AllPlans from "./Pages/AllPlans";
// import Subscription from "./Pages/Subscription";
import Analytics from "./Pages/Analytics";
import ErrorLogs from "./Pages/ErrorLogs";
import ContactUsList from "./Pages/ContactUsList";
import TryingToSignup from "./Pages/TryingToSignup";
import ReviewArticleOrPostHistory from "./Pages/ReviewArticleOrPostHistory";
import ReviewArticleOrPostDetails from "./Pages/ReviewArticleOrPostDetails";
import ECommerce from "./Pages/ECommerceHistory";
import ECommerceBlogDetails from "./Pages/ECommerceBlogDetails";
import AzgoNewsletterHistory from "./Pages/AzgoNewsletterHistory";
import AzgoNewsletterDetails from "./Pages/AzgoNewsletterDetails";
import CmiNewsletterHistory from "./Pages/CmiNewsletterHistory";
import CmiNewsletterDetails from "./Pages/CmiNewsletterDetails";
import InvestIdeaDetails from "./Pages/InvestIdeaDetails";
import InvestIdeaHistory from "./Pages/InvestIdeaHistory";
import RysenseBlogDetails from "./Pages/RysenseBlogDetails";
import RysenseBlogHistory from "./Pages/RysenseBlogHistory";
import NewCreditUsagePage from "./Pages/NewCreditUsagePage";

const ALL_ROUTES = [
  {
    path: "/login",
    Component: Login,
    isPrivate: false,
    access: ["admin", "sales", "reviewer"],
  },
  {
    path: "/",
    Component: ArticleHistory,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/users",
    Component: Users,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/admins",
    Component: Admins,
    isPrivate: true,
    access: ["admin"],
  },
  {
    path: "/organizations",
    Component: Organizations,
    isPrivate: true,
    access: ["admin"],
  },
  {
    path: "/history-details/:id",
    Component: ArticleHistoryDetails,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/article-optimized-blog",
    Component: ArticleOptimizedBlogHistory,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/article-optimized-blog/:id",
    Component: ArticleOptimizedBlogDetails,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/e-commerce/:id",
    Component: ECommerceBlogDetails,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/social-media-post",
    Component: SocialMediaPostHistory,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/social-media-post/:id",
    Component: SocialMediaPostDetails,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  /* {
    path: "/brand-product-images",
    Component: BrandProductImagesHistory,
    isPrivate: true,
    access: ["admin", "sales"],
  }, */
  {
    path: "/media-library-images",
    Component: MediaLibraryImagesHistory,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/news-letters",
    Component: NewslettersHistory,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/e-commerce",
    Component: ECommerce,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/news-letters/:id",
    Component: NewslettersDetails,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/press-release",
    Component: PressReleaseHistory,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/press-release/:id",
    Component: PressReleaseDetails,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/topic-suggestions",
    Component: TopicSuggestionsHistory,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/topic-suggestions/:id",
    Component: TopicSuggestionsDetails,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/invest-idea-history/:id",
    Component: InvestIdeaDetails,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/invest-idea-history",
    Component: InvestIdeaHistory,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/tone-of-voice",
    Component: ToneOfVoiceHistory,
    isPrivate: true,
    access: ["admin"],
  },
  {
    path: "/system-prompts",
    Component: SystemPromptsHistory,
    isPrivate: true,
    access: ["admin"],
  },
  {
    path: "/deduct-credits",
    Component: DeductCreditsDetails,
    isPrivate: true,
    access: ["admin"],
  },
  /* {
    path: "/blog-creation",
    Component: BlogCreation,
    isPrivate: true,
    access: ["admin"],
  },
  {
    path: "/refund-requests",
    Component: RefundRequests,
    isPrivate: true,
    access: ["admin"],
  }, */
  {
    path: "/refund-credit-requests",
    Component: RefundCreditRequests,
    isPrivate: true,
    access: ["admin"],
  },
  {
    path: "/user-transaction-history/:userId",
    Component: UserTransactionHistory,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/user-log/:userId",
    Component: UserLog,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/all-invoices",
    Component: Invoices,
    isPrivate: true,
    access: ["admin"],
  },
  {
    path: "/create-plan",
    Component: CreatePlans,
    isPrivate: true,
    access: ["admin"],
  },
  {
    path: "/create-plan/:id",
    Component: CreatePlans,
    isPrivate: true,
    access: ["admin"],
  },
  /* {
    path: "/product",
    Component: Products,
    isPrivate: true,
    access: ["admin"],
  },
  {
    path: "/discount-coupan",
    Component: DiscountCoupan,
    isPrivate: true,
    access: ["admin"],
  },
  {
    path: "/subscriptions",
    Component: Subscription,
    isPrivate: true,
    access: ["admin"],
  }, */
  {
    path: "/all-plans",
    Component: AllPlans,
    isPrivate: true,
    access: ["admin"],
  },
  {
    path: "/analytics",
    Component: Analytics,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/error-logs/:type",
    Component: ErrorLogs,
    isPrivate: true,
    access: ["admin"],
  },
  {
    path: "/contact-us",
    Component: ContactUsList,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/trying-to-signup",
    Component: TryingToSignup,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/",
    Component: ReviewArticleOrPostHistory,
    isPrivate: true,
    access: ["reviewer"],
  },
  {
    path: "/article-optimized-blog",
    Component: ReviewArticleOrPostHistory,
    isPrivate: true,
    access: ["reviewer"],
  },
  {
    path: "/social-media-post",
    Component: ReviewArticleOrPostHistory,
    isPrivate: true,
    access: ["reviewer"],
  },
  {
    path: "/news-letters",
    Component: ReviewArticleOrPostHistory,
    isPrivate: true,
    access: ["reviewer"],
  },
  {
    path: "/press-release",
    Component: ReviewArticleOrPostHistory,
    isPrivate: true,
    access: ["reviewer"],
  },
  {
    path: "/review-article/:type/:articleId",
    Component: ReviewArticleOrPostDetails,
    isPrivate: true,
    access: ["reviewer"],
  },
  {
    path: "/azgo-newsletter",
    Component: AzgoNewsletterHistory,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/azgo-newsletter/:id",
    Component: AzgoNewsletterDetails,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/cmi-newsletter",
    Component: CmiNewsletterHistory,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/cmi-newsletter/:id",
    Component: CmiNewsletterDetails,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/happydot-blog",
    Component: RysenseBlogHistory,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/happydot-blog/:id",
    Component: RysenseBlogDetails,
    isPrivate: true,
    access: ["admin", "sales"],
  },
  {
    path: "/credit-usage-new",
    Component: NewCreditUsagePage,
    isPrivate: true,
    access: ["admin", "sales"]
  }
];

const LoaderComponent = () => {
  return (
    <div>Loading...</div>
  )
}

function App() {
  const dispatch = useDispatch();
  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);
  const loginDetails = useSelector(({ loginDetails }) => loginDetails);
  const [refundReqNo, setRefundReqNo] = useState();
  const [accessRoutes, setAccessRoutes] = useState([
    {
      path: "/login",
      Component: Login,
      isPrivate: false,
    }, {
      path: "*",
      Component: LoaderComponent,
      isPrivate: true,
    }
  ]);

  useEffect(() => {
    if (loginDetails && loginDetails.role) {
      const activeRouteList = [];
      ALL_ROUTES.filter((route) => {
        if (route.access.includes(loginDetails.role)) {
          activeRouteList.push(route);
        }
        return route;
      });
      setAccessRoutes(activeRouteList);

      if (loginDetails.role === "admin") {
        handleGetRefundReqData();
      }
    }
  }, [])


  const handleGetRefundReqData = () => {
    if (loginDetails.role === "admin") {
      AxiosApi.get(`${apiBaseUrl}/api/user/credit-transactions?status=pending`).then((res) => {
        if (res.data && res.data.length > 0) {
          setRefundReqNo(res.data.length)
        }
      }).catch((error) => {
        console.log('#error --->', error);
      });
    }
  }

  AxiosApi.interceptors.response.use((response) => response, async (error) => {
    if (error.response.status === 401) {
      await dispatch(resetReduxData());
      dispatch(setLoginDetails({
        username: "",
        password: "",
      }));
      localStorage.removeItem("persist:root");
      localStorage.clear();

      setTimeout(() => {
        window.location.href = '/';
      }, 600);
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  });

  const PrivateRoute = ({ children }) => {
    if (loginDetails?.username === "" && loginDetails?.password === "") {
      let tempUrl = `${window.location.pathname}${window.location.search}`;
      tempUrl = tempUrl.replaceAll("+", " ");
      Cookies.set("redirect-url", tempUrl);
      return <Navigate to="/login" />;
    }
    return children;
  };

  return (
    <div>
      <BrowserRouter>
        <Routes>
          {accessRoutes.map(({ Component, path, isPrivate, navigate }) => (
            <Route
              key={path}
              path={path}
              element={
                isPrivate ? (
                  <PrivateRoute>
                    {navigate ? (
                      <Navigate to={navigate} />
                    ) : (
                      <HeaderSidebar refundReqNo={refundReqNo}>
                        <Component handleGetRefundReqData={handleGetRefundReqData} />
                      </HeaderSidebar>
                    )}
                  </PrivateRoute>
                ) : (
                  <Component />
                )
              }
            />
          ))}
          {/* <Route path="*" element={<Navigate replace to="/" />} /> */}
        </Routes>
      </BrowserRouter>

      <Toaster
        position="top-right"
        toastOptions={{
          error: { className: "alert error toast-contain" },
          success: { className: "alert success toast-contain" },
        }}
      >
        {(t) => (
          <ToastBar
            toast={t}
            style={{ ...t.style, animation: t.visible ? "custom-enter 1s ease" : "custom-exit 1s ease" }}
            children={(props) => (
              <div>
                {props.message}
                <p className="close-error">
                  {props.icon}
                </p>
              </div>
            )}
          />
        )}
      </Toaster>

      <Tooltip id="my-tooltip" style={{ borderRadius: "6px", fontSize: "16px", padding: "4px 12px", maxWidth: "200px", zIndex: 9999 }} />
      <Loader />
    </div>
  );
}

export default App;
