import React from 'react'

const transactions = [
    {
        date: "Yesterday 11:48:45",
        generationType: "Blog",
        creditsDeducted: "-2",
        creditsAvailable: "85",
    },
    {
        date: "Yesterday 11:47:55",
        generationType: "Social Media",
        creditsDeducted: "-3",
        creditsAvailable: "87",
    },
    {
        date: "Yesterday 11:08:02",
        generationType: "Newsletter",
        creditsDeducted: "-2",
        creditsAvailable: "90",
    },
    {
        date: "Yesterday 11:07:04",
        generationType: "Blog",
        creditsDeducted: "-3",
        creditsAvailable: "92",
    },
    {
        date: "22/11/2024 17:18:05",
        generationType: "Social Media",
        creditsDeducted: "-2",
        creditsAvailable: "95",
    },
    {
        date: "22/11/2024 17:17:13",
        generationType: "Newsletter",
        creditsDeducted: "-3",
        creditsAvailable: "97",
    },
    
];

const data = [
    {
        id: 118,
        articleDetails: "Exploring Residential Properties",
        type: "Short Blog",
        lastModified: "Nov 25 2024 - 6:17 am",
        aiType: "GPT 4",
        status: "Done",
        language: "English",
    },
    {
        id: 117,
        articleDetails: "Short Blog",
        type: "Short Blog",
        lastModified: "Nov 25 2024 - 5:36 am",
        aiType: "GPT 4",
        status: "In Progress",
        language: "English",
    },
    {
        id: 116,
        articleDetails: "Short Blog",
        type: "Short Blog",
        lastModified: "Nov 25 2024 - 5:25 am",
        aiType: "GPT 4",
        status: "In Progress",
        language: "English",
    },
    {
        id: 111,
        articleDetails: "Short Blog",
        type: "Short Blog",
        lastModified: "Nov 22 2024 - 11:46 am",
        aiType: "GPT 4",
        status: "In Progress",
        language: "English",
    },
    {
        id: 110,
        articleDetails: "Short Blog",
        type: "Short Blog",
        lastModified: "Nov 22 2024 - 11:44 am",
        aiType: "GPT 4",
        status: "In Progress",
        language: "English",
    },
    {
        id: 109,
        articleDetails: "Short Blog",
        type: "Short Blog",
        lastModified: "Nov 22 2024 - 11:00 am",
        aiType: "GPT 4",
        status: "In Progress",
        language: "English",
    },
    {
        id: 108,
        articleDetails: "Short Blog",
        type: "Short Blog",
        lastModified: "Nov 22 2024 - 10:54 am",
        aiType: "GPT 4",
        status: "In Progress",
        language: "English",
    },
    {
        id: 97,
        articleDetails: "Exploring Neighbourly Connections",
        type: "Short Blog",
        lastModified: "Nov 21 2024 - 10:28 am",
        aiType: "GPT 4",
        status: "Done",
        language: "English",
    },
    {
        id: 96,
        articleDetails: "Short Blog",
        type: "Short Blog",
        lastModified: "Nov 21 2024 - 10:00 am",
        aiType: "GPT 4",
        status: "In Progress",
        language: "English",
    },
    {
        id: 95,
        articleDetails: "Short Blog",
        type: "Short Blog",
        lastModified: "Nov 21 2024 - 9:45 am",
        aiType: "GPT 4",
        status: "Done",
        language: "English",
    },
];

const NewCreditUsagePage = () => {
    return (
        <React.Fragment>
            <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
                <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                    <h3>Client's usage History</h3>
                    <div className="d-flex align-items-center gap-4">
                        <input
                            type="text"
                            placeholder="Search..."
                            style={{
                                flex: 1,
                                padding: "10px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                            }}
                            autoComplete="on"
                        />
                        <div className="d-flex align-items-center gap-2">
                            <label htmlFor="date-from">From: </label>
                            <input
                                type="date"
                                id="date-from"
                                style={{
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                }}
                            />
                            <label htmlFor="date-to">To: </label>
                            <input
                                type="date"
                                id="date-to"
                                style={{
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                }}
                            />
                        </div>
                        <button
                            style={{
                                padding: "10px 20px",
                                backgroundColor: "#007bff",
                                color: "#fff",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                            }}
                        >
                            Search
                        </button>
                    </div>

                    {/* Main Content Section */}
                    <div style={{ display: "flex", gap: "20px", flex: 1 }}>
                        {/* Left Section */}
                        <div
                            style={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                                gap: "20px",
                            }}
                        >
                            {/* Card: Generated Items */}
                            <div
                                style={{
                                    background: "#f8f9fa",
                                    padding: "20px",
                                    borderRadius: "10px",
                                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                                }}
                            >
                                <h3>Generated Items</h3>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        marginTop: "10px",
                                        gap: "3px"
                                    }}
                                >
                                    <div className="d-flex justify-content-between">
                                        <strong>Blogs:</strong> <span>10</span>
                                    </div >
                                    <hr />
                                    <div className="d-flex justify-content-between">
                                        <strong>Newsletters:</strong> <span>5</span>
                                    </div >
                                    <hr />
                                    <div className="d-flex justify-content-between">
                                        <strong>Social Media:</strong> <span>8</span>
                                    </div>
                                </div>
                            </div>

                            {/* Card: Credits Overview */}
                            <div
                                style={{
                                    background: "#f8f9fa",
                                    padding: "20px",
                                    borderRadius: "10px",
                                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                                }}
                            >
                                <h3>Credit's Overview</h3>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        marginTop: "10px",
                                        gap: "3px"
                                    }}
                                >
                                    <div className="d-flex justify-content-between">
                                        <strong>Total Credits:</strong> <span>100</span>
                                    </div>
                                    <hr />
                                    <div className="d-flex justify-content-between">
                                        <strong>Credits Used:</strong> <span>15</span>
                                    </div>
                                    <hr />
                                    <div className="d-flex justify-content-between">
                                        <strong>Credits Remaining:</strong> <span>85</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Right Section */}
                        <div
                            style={{
                                flex: 1,
                                padding: "20px",
                                background: "#f8f9fa",
                                borderRadius: "10px",
                                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                            }}
                        >
                            <h3 className ="mb-3">Credit Transactions</h3>
                            <div>
                                <div
                                    style={{
                                        border: "1px solid #ccc",
                                        borderRadius: "10px",
                                        background: "#fff",
                                        overflow: "hidden",
                                    }}
                                >
                                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                        <thead>
                                            <tr style={{ background: "#f8f9fa", textAlign: "left" }}>
                                                <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
                                                    Date
                                                </th>
                                                <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
                                                    Generation Type
                                                </th>
                                                <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
                                                    Credits Deducted
                                                </th>
                                                <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
                                                    Credits Available
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transactions.map((transaction, index) => (
                                                <tr key={index} style={{ borderBottom: "1px solid #eee" }}>
                                                    <td style={{ padding: "10px" }}>{transaction.date}</td>
                                                    <td style={{ padding: "10px" }}>{transaction.generationType}</td>
                                                    <td
                                                        style={{
                                                            padding: "10px",
                                                            color: "red",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {transaction.creditsDeducted}
                                                    </td>
                                                    <td style={{ padding: "10px" }}>{transaction.creditsAvailable}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Tabs Section */}
                    <div>
                        <h3 className="mb-3">Generated History</h3>
                        <div className="d-flex mb-2 gap-3">
                            <button style={{
                                padding: "10px 20px",
                                backgroundColor: "#007bff",
                                color: "#fff",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                            }}>Blogs</button>
                            <button style={{
                                padding: "10px 20px",
                                backgroundColor: "#007bff",
                                color: "#fff",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                            }}>Social Media</button>
                            <button style={{
                                padding: "10px 20px",
                                backgroundColor: "#007bff",
                                color: "#fff",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                            }}>Newsletters</button>
                        </div>
                        {/* <div
                            style={{
                                border: "1px solid #ccc",
                                borderRadius: "10px",
                                padding: "20px",
                                background: "#fff",
                            }}
                        >
                            <p>Tab content will be displayed here...</p>
                        </div> */}
                        {/* <div className= "dashboard-history border-radius-12 bg-white p-3 p-xl-4 mt-4"> */}
                            <div
                                style={{
                                    border: "1px solid #ccc",
                                    borderRadius: "10px",
                                    background: "#fff",
                                    overflow: "hidden",
                                }}
                            >
                                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                    <thead>
                                        <tr style={{ background: "#f8f9fa", textAlign: "left" }}>
                                            <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>ID</th>
                                            <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
                                                Article Details
                                            </th>
                                            <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>Type</th>
                                            <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
                                                Last Modified
                                            </th>
                                            <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>AI Type</th>
                                            <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>Status</th>
                                            <th style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>Language</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((row, index) => (
                                            <tr key={index} style={{ borderBottom: "1px solid #eee" }}>
                                                <td style={{ padding: "10px" }}>{row.id}</td>
                                                <td style={{ padding: "10px" }}>{row.articleDetails}</td>
                                                <td style={{ padding: "10px" }}>{row.type}</td>
                                                <td style={{ padding: "10px" }}>{row.lastModified}</td>
                                                <td style={{ padding: "10px" }}>{row.aiType}</td>
                                                <td
                                                    style={{
                                                        padding: "10px",
                                                        color: row.status === "Done" ? "green" : "orange",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {row.status}
                                                </td>
                                                <td style={{ padding: "10px" }}>{row.language}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                {/* </div> */}
            </div>
        </React.Fragment>
    )
}

export default NewCreditUsagePage